/* eslint-disable prettier/prettier */
// https://api.coingecko.com/api/v3/simple/price?ids=avalanche-2&vs_currencies=USD

import React, { useState, useEffect } from 'react';

const AvaxPriceList = () => {
  const [price, setPrice] = useState(null);

  useEffect(() => {
    const fetchPrice = async () => {
      try {
        const response = await fetch(
          'https://api.coingecko.com/api/v3/simple/price?ids=avalanche-2&vs_currencies=USD'
        );
        const data = await response.json();
        setPrice(data['avalanche-2'].usd);
      } catch (error) {
        console.error('Error fetching AVAX price:', error);
      }
    };

    fetchPrice();
  }, []);

  return (
    <>
      {price ? (
        <>${price}</>
      ) : (
        <>Loading...</>
      )}
    </>
  );
};

export default AvaxPriceList;