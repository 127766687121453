/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */
import { Button, DatePicker, Divider, Input, Progress, Slider, Spin, Switch } from "antd";
import React, { useState, useCallback, useEffect } from "react";
import { ethers, utils } from "ethers";
import { useContractReader, useUserProviderAndSigner } from "eth-hooks";
import { Grid, Paper, Container, Card, Box} from "@mui/material";

export default function Home({ 
  exchangerate,
  address,
  mainnetProvider,
  localProvider,
  yourLocalBalance,
  price,
  tx,
  readContracts,
  writeContracts,
  targetNetwork,
  setDynamicTitle,
}) {

  useEffect(() => {
    setDynamicTitle('Smart Agreement System');
  }, []);

  return (
      <div>
          <Paper className='homebox shadow' elevation={1}>
            <h1 className="headline">
              Smart contracts for every day situations.
            </h1>
            <p className="subhead">The most powerful agreement system to build and scale your smart contracts with ease.</p>
          </Paper>
          
          <Grid container direction="row" justifyContent="space-between" alignItems="center" spacing={3} gap={2} sx={{marginTop:'0px',}}>
            <Grid item xs>
              <Paper className='steps shadow' elevation={1}>
                <h5>STEP 1</h5>
                <h2>Create A Wallet</h2>
                <p>You'll need to setup a wallet in order to safely store your tokens (cryptocurrencies) online.</p>
                <a href="./wallet">
                  <Button>
                      Create Wallet
                  </Button>
                </a>
              </Paper>
            </Grid>

            <Grid item xs>
              <Paper className='steps shadow' elevation={1}>
                <h5>STEP 2</h5>
                <h2>Buy Avalanche</h2>
                <p>Purchase Avalanche and trade it for Papre token. That is how you fund your smart contract.</p>
                <a href="./buyavalanche">
                <Button>
                    Buy Avalanche
                </Button>
                </a>
              </Paper>
            </Grid>

            <Grid item xs>
              <Paper className='steps shadow' elevation={1}>
                <h5>STEP 3</h5>
                <h2>Buy Papre</h2>
                <p>Trade your Avalanche for Papre tokens. Current round of Papre is only 10,000 tokens. Hurry before they sell out.</p>
                <a href="./buypapre">
                <Button>
                    Buy Papre
                </Button>
                </a>
              </Paper>
            </Grid>

          </Grid>
      </div>
  );
}
