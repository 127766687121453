/* eslint-disable no-unused-vars */
/* eslint-disable prettier/prettier */
/// 6/29/22 -> Need to figure out error. Re-install Scaffold-Eth and figure out whether there's some reference 
///            YourContract that isn't replicated with DEX.cd

import {Input} from "antd";
import React, { useState, useCallback, useEffect } from "react";
import { ethers, utils } from "ethers";
import { useTokenList } from "eth-hooks/dapps/dex";
import { Address, Balance, Events, FaucetHint, Account, TokenBalance, Faucet, AvaxPriceList, PaprePrice, } from "../components";
import { useContractReader, useUserProviderAndSigner } from "eth-hooks";
import { useExchangeEthPrice } from "eth-hooks/dapps/dex";
import { Transactor, Web3ModalSetup } from "../helpers";
import { autocompleteClasses, Grid, Paper, Divider, Button, } from "@mui/material";
import { FaEthereum, FaPaperPlane} from 'react-icons/fa'
import { ReactComponent as AvaxLogoSVG } from '../components/avax-logo.svg';
import AvaxPrice from "../components/AvaxPrice";
import PapreUSD from "../components/AvaxPrice";

const { BigNumber } = require("ethers");
const USE_BURNER_WALLET = false; // toggle burner wallet feature

export default function BuyPapre({ 
  exchangerate,
  address,
  mainnetProvider,
  localProvider,
  yourLocalBalance,
  price,
  tx,
  readContracts,
  writeContracts,
  targetNetwork,
  setDynamicTitle,
}) {
  // Get a list of tokens from a tokenlist -> see tokenlists.org!
  const [ETHAmountInUSD, setAmount] = useState(0);
  const [ETHamount, setETHamount] = useState(0);
  const [ExchangeRate, setExchangeRate] = useState(0);
  ///const [selectedToken, setSelectedToken] = useState("Pick a token!");
  const [injectedProvider, setInjectedProvider] = useState();
  ///const [faucetAddress, setFaucetAddress] = useState();
  /*const listOfTokens = useTokenList(
    "https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/set.tokenlist.json",
  );*/
  const priceOfEth = 14.46;///useExchangeEthPrice(targetNetwork, mainnetProvider);
  ///const priceOfPapre = 1/priceOfEth; 

  /*const currentcyFormat = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD"
  });*/

  const web3Modal = Web3ModalSetup();
  const userProviderAndSigner = useUserProviderAndSigner(injectedProvider, localProvider, USE_BURNER_WALLET);
  const userSigner = userProviderAndSigner.signer;
  const loadWeb3Modal = useCallback(async () => {
    const provider = await web3Modal.connect();
    setInjectedProvider(new ethers.providers.Web3Provider(provider));

    provider.on("chainChanged", chainId => {
      console.log(`chain changed to ${chainId}! updating providers`);
      setInjectedProvider(new ethers.providers.Web3Provider(provider));
    });

    provider.on("accountsChanged", () => {
      console.log(`account changed!`);
      setInjectedProvider(new ethers.providers.Web3Provider(provider));
    });

    // Subscribe to session disconnection
    provider.on("disconnect", (code, reason) => {
      console.log(code, reason);
      logoutOfWeb3Modal();
    });
    // eslint-disable-next-line
  }, [setInjectedProvider]);

  const logoutOfWeb3Modal = async () => {
    await web3Modal.clearCachedProvider();
    if (injectedProvider && injectedProvider.provider && typeof injectedProvider.provider.disconnect == "function") {
      await injectedProvider.provider.disconnect();
    }
    setTimeout(() => {
      window.location.reload();
    }, 1);
  };

  //const targetNetwork = NETWORKS[selectedNetwork];
  const blockExplorer = targetNetwork.blockExplorer;

  const [inputValue, setInputValue] = useState('');
  const [calculatedValue, setCalculatedValue] = useState(0);
  const [usdValue, setUsdValue] = useState(0);

  ///const { Option } = Select;
   useEffect(() => {
    setDynamicTitle('Buy Papre');
  }, []);
  
  return (
      <div>
        
        <Grid container direction="row" justifyContent="space-between" alignItems="flex-start" spacing={0} gap={3} sx={{marginTop:'0px', zIndex:'0',}}>
            <Grid item xs={12} md={5}>
              <Paper className='steps shadow' elevation={1}>

              <p style={{fontSize:"17px",textAlign:'center',}}>How much Papre do you want to buy?</p>
          
                <Grid item xs={12} justifyContent="center" alignContent="center" sx={{borderBottom:"1px solid #eff0f4", paddingBottom:'6px', display:"flex", minWidth:'288px', maxWidth:'100%',}}>
                  <Input
                    className="bpapreinput"
                    style={{textAlign: "center", padding:"0px", borderRadius:"0", border:"0px solid #00000000", color:"#292d32",fontSize:"44px", fontWeight:"600", }}
                    placeholder={"Papre Amount"}
                    prefix={<FaPaperPlane color="#000" fontSize={"36px"} style={{position:"relative", top:"2px",}} />}
                    onChange={e => {
                      setAmount(e.target.value/3);
                      const value = e.target.value;
                      setInputValue(value);
                      setCalculatedValue((value / 3).toFixed(2));
                      setUsdValue((value * (priceOfEth /3)).toFixed(2));
                    }}
                  />
                </Grid>

                <Grid container justifyContent="center" alignItems="center" width={"100%"} p={4}>
                  <Grid item sx={{borderRadius:"15px", backgroundColor:"#dbdbdb",fontSize:"12px",padding:"8px 12px", margin:"5px",}}>USD | ${usdValue}</Grid>
                  <Grid item sx={{borderRadius:"15px", backgroundColor:"#dbdbdb",fontSize:"12px",padding:"8px 12px", margin:"5px",}}><div style={{display:"inline-block", position:"relative", top:"2px"}}><AvaxLogoSVG width={"17px"} height={"15px"} fill={"#000"} /></div> AVAX | {calculatedValue}</Grid>
                </Grid>
                
                <Button
                  style={{textAlign:'center', width:"100%",padding:"1.125rem", backgroundColor:"#e48426", fontSize:"1rem", fontWeight:"normal", color:"#fff", borderRadius:"3.9375rem", height:"auto", boxShadow:'0px 10px 20px #00000021', border:'0px', }}
                  
                  onClick={async () => {
                    /* look how you call setPurpose on your contract: */
                    /* notice how you pass a call back for tx updates too */
                    console.log("targetNetwork.price");
                    console.log(targetNetwork.price);
                    const options = {value: ethers.utils.parseEther(ETHAmountInUSD.toFixed(18).toString())};
                    
                    console.log("ETHamount =", ETHAmountInUSD, options);
                    const result = await tx(writeContracts.DEX.buy(options), update => {
                      console.log("📡 Transaction Update:", update);
                      if (update && (update.status === "confirmed" || update.status === 1)) {
                        console.log(" 🍾 Transaction " + update.hash + " finished!");
                        console.log(
                          " ⛽️ " +
                            update.gasUsed +
                            "/" +
                            (update.gasLimit || update.gas) +
                            " @ " +
                            parseFloat(update.gasPrice) / 1000000000 +
                            " gwei",
                        );
                      }
                    });
                      console.log("awaiting metamask/web3 confirm result...", result);
                      console.log(await result);
                    }}
                >
                  Buy Papre
                </Button>
                
                {/*<Faucet
                    price={price}
                    localProvider={localProvider}
                    ensProvider={mainnetProvider}
                    placeholder={"Send local faucet"}
                />*/}

              </Paper>
            </Grid>

            <Grid item xs>
                <Paper className='steps shadow' elevation={1}>
                    <h5>Are You Ready?</h5>
                    <h2>Make sure you've followed the first two steps</h2>
                    <ol>
                      <li>Make sure you have enough Avalanche in your wallet to get started.</li>
                      <li>On this page, you will be trading your Avalanche for Papre.</li>
                      <li>Choose the amount of Papre that you would like to buy.</li>
                    </ol>
                <Divider sx={{my:4,}} />
                    <h5>Purchase Papre With Avalanche</h5>
                    <h2>Papre is Approximately <PaprePrice /> USD per token.</h2>
                    <p><strong>For each 1 Avax you will recieve 3 Papre</strong></p>
                    <p>You have <Balance fontSize={"11px"} color={"#fff"} address={address} provider={localProvider} price={price}/> AVAX Available</p>
                    <p>If you need more AVAX to buy Papre, you can purchase more AVAX through MetaMask</p>
                <Divider sx={{my:4,}} />
                    <h5>Pricing</h5>
                    <h2>Costs associated in trading Avalanche for Papre</h2>
                    <p>Cost per 1 <FaPaperPlane color="#000" fontSize={"12px"} /> Papre is <PaprePrice /> USD</p>
                    <p>Cost per 1 Avax is <AvaxPriceList /> USD</p>
                </Paper>
            </Grid>
        </Grid>
      </div>
  );
}
