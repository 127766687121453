/* eslint-disable prettier/prettier */
import React, { useState, useEffect } from "react";
import { ethers } from "ethers";

const AvaxPriceRyan = () => {
  const [avaxPrice, setAvaxPrice] = useState(null);

  useEffect(() => {
    const fetchAvaxPrice = async () => {
      try {
        const abi = new ethers.abi();
        const provider = new ethers.providers.JsonRpcProvider("https://api.avax.network/ext/bc/C/rpc");
        const avaxContract = new ethers.Contract("0x2f99a7a81a3a446db9fdfcab10c47a406f874b50", abi, provider);
        const price = await avaxContract.getPrice(); // Assuming there's a getPrice() function on the AVAX token contract

        // Convert the price to desired format, e.g., USD
        const formattedPrice = convertToUSD(price);

        setAvaxPrice(formattedPrice);
      } catch (error) {
        console.error("Error fetching AVAX price:", error);
      }
    };

    fetchAvaxPrice();
  }, []);

  const convertToUSD = (price) => {
    // Conversion logic goes here, e.g., using an API or a static conversion rate
    // Return the formatted price in USD or your desired format
  };

  return (
    <div>
      {avaxPrice ? (
        <p>AVAX Price: {avaxPrice}</p>
      ) : (
        <p>Loading AVAX price...</p>
      )}
    </div>
  );
};

export default AvaxPriceRyan;