/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */
//import * as React from "react";
import { Button, Col, Menu, Row } from "antd";
import "antd/dist/antd.css";
import {
  useBalance,
  useContractLoader,
  useContractReader,
  useGasPrice,
  useOnBlock,
  useUserProviderAndSigner,
} from "eth-hooks";
import { useExchangeEthPrice } from "eth-hooks/dapps/dex";
import React, { useCallback, useEffect, useState } from "react";
import { Link, Route, Switch, Redirect, useLocation } from "react-router-dom";
import "./App.css";
import {
  Account,
  Contract,
  Faucet,
  GasGauge,
  Header,
  Ramp,
  ThemeSwitch,
  NetworkDisplay,
  FaucetHint,
  NetworkSwitch,
} from "./components";
import { ReactComponent as AvaxLogoSVG } from './components/avax-logo.svg';
import { NETWORKS, ALCHEMY_KEY } from "./constants";
import externalContracts from "./contracts/external_contracts";
// contracts
import deployedContracts from "./contracts/hardhat_contracts.json";
import { Transactor, Web3ModalSetup } from "./helpers";
import { Home, Wallet, BuyAvalanche, BuyPapre } from "./views";
import { useStaticJsonRPC } from "./hooks";

import { styled, useTheme, withStyles} from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import RestoreIcon from "@mui/icons-material/Restore";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import Paper from "@mui/material/Paper";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import Drawer from '@mui/material/Drawer';

// MUI Footer
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import { autocompleteClasses, Grid } from "@mui/material";

// FONT AWESOME / ICONS
import { FaEthereum, FaPaperPlane, FaCogs, FaQuestionCircle, FaFastForward, FaWallet } from 'react-icons/fa'
import { VscDebug } from "react-icons/vsc";

///***Price***/
import { Token, WETH, Fetcher, Route as Route2 } from '@uniswap/sdk';

const drawerWidth = 240;

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open"
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  })
}));



const { ethers } = require("ethers");
/*
    Welcome to 🏗 scaffold-eth !

    Code:
    https://github.com/scaffold-eth/scaffold-eth

    Support:
    https://t.me/joinchat/KByvmRe5wkR-8F_zz6AjpA
    or DM @austingriffith on twitter or telegram

    You should get your own Alchemy.com & Infura.io ID and put it in `constants.js`
    (this is your connection to the main Ethereum network for ENS etc.)


    🌏 EXTERNAL CONTRACTS:
    You can also bring in contract artifacts in `constants.js`
    (and then use the `useExternalContractLoader()` hook!)
*/

/// 📡 What chain are your contracts deployed to?
const initialNetwork = NETWORKS.mainnetAvalanche; // <------- select your target frontend network (localhost, rinkeby, xdai, mainnet)

// 😬 Sorry for all the console logging
const DEBUG = false;
const NETWORKCHECK = true;
const USE_BURNER_WALLET = false; // toggle burner wallet feature
const USE_NETWORK_SELECTOR = true;

const web3Modal = Web3ModalSetup();

// 🛰 providers
const providers = [
  "https://api.avax.network/ext/bc/C/rpc",
  /*"https://eth-mainnet.gateway.pokt.network/v1/lb/611156b4a585a20035148406",
  `https://eth-mainnet.alchemyapi.io/v2/${ALCHEMY_KEY}`,
  "https://rpc.scaffoldeth.io:48544",*/
];

export default function App(props) {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  ///***Price***/
  const [price, setPrice] = useState(0);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  // specify all the chains your app is available on. Eg: ['localhost', 'mainnet', ...otherNetworks ]
  // reference './constants.js' for other networks
  const networkOptions = [initialNetwork.name, "fujiAvalanche", "mainnetAvalanche", "localhost", "mainnet", "goerli"];

  const [injectedProvider, setInjectedProvider] = useState();
  const [address, setAddress] = useState();
  const [selectedNetwork, setSelectedNetwork] = useState(networkOptions[0]);
  const location = useLocation();

  const { window } = props;

  const targetNetwork = NETWORKS[selectedNetwork];
    
  // 🔭 block explorer URL
  const blockExplorer = targetNetwork.blockExplorer;

  // load all your providers
  const localProvider = useStaticJsonRPC([
    process.env.REACT_APP_PROVIDER ? process.env.REACT_APP_PROVIDER : targetNetwork.rpcUrl,
  ]);
  const mainnetProvider = useStaticJsonRPC(providers);
  console.log("mainnetProvider = ", mainnetProvider);
  ///console.log("chainId =", targetNetwork.getNetwork().chainid);

  ///***Price***/
  /*const getPrice = async () => {
    const network = await mainnetProvider.getNetwork();
    /// DAI stablecoin address on Avalanche C-Chain: 0xd586E7F844cEa2F87f50152665BCbc2C279D8d70
    const DAI = new Token(network ? network.chainId : 1, '0xd586E7F844cEa2F87f50152665BCbc2C279D8d70', 18);
    console.log("DAI.chainId = ", DAI.chainId);
    const WAVAX = new Token(network ? network.chainId : 1, '0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7', 18);
    const pair = await Fetcher.fetchPairData(DAI, WAVAX, mainnetProvider);
    const route = new Route2([pair], WAVAX);
    setPrice(parseFloat(route.midPrice.toSignificant(6)));
  }
  getPrice();*/
  /*useEffect(() => {
    const getPrice = async () => {
    const avaxPrice = await getAvaxPrice;
    setPrice(avaxPrice);
    console.log("avaxPrice (again) = ", avaxPrice);
    }
    getPrice();
  }, []);
  console.log("price = ", price);*/

  if (DEBUG) console.log(`Using ${selectedNetwork} network`);

  // 🛰 providers
  if (DEBUG) console.log("📡 Connecting to Mainnet Ethereum");

   const logoutOfWeb3Modal = async () => {
  
    await web3Modal.clearCachedProvider();
    if (injectedProvider && injectedProvider.provider && typeof injectedProvider.provider.disconnect == "function") {
      await injectedProvider.provider.disconnect();
    }
      setTimeout(() => {
       document.location.reload();
     }, 1);
    
    console.log("this is the location", location);
  };

  /* 💵 This hook will get the price of ETH from 🦄 Uniswap: */
  
  ///const price = useExchangeEthPrice(targetNetwork, mainnetProvider);
 
  /* 🔥 This hook will get the price of Gas from ⛽️ EtherGasStation */
  const gasPrice = useGasPrice(targetNetwork, "fast");
  // Use your injected provider from 🦊 Metamask or if you don't have it then instantly generate a 🔥 burner wallet.
  const userProviderAndSigner = useUserProviderAndSigner(injectedProvider, localProvider, USE_BURNER_WALLET);
  const userSigner = userProviderAndSigner.signer;

  useEffect(() => {
    async function getAddress() {
      if (userSigner) {
        const newAddress = await userSigner.getAddress();
        setAddress(newAddress);
      }
    }
    getAddress();
  }, [userSigner]);

  // You can warn the user if you would like them to be on a specific network
  const localChainId = localProvider && localProvider._network && localProvider._network.chainId;
  const selectedChainId =
    userSigner && userSigner.provider && userSigner.provider._network && userSigner.provider._network.chainId;

  // For more hooks, check out 🔗eth-hooks at: https://www.npmjs.com/package/eth-hooks

  // The transactor wraps transactions and provides notificiations
  const tx = Transactor(userSigner, gasPrice);

  // 🏗 scaffold-eth is full of handy hooks like this one to get your balance:
  const yourLocalBalance = useBalance(localProvider, address);

  // Just plug in different 🛰 providers to get your balance on different chains:
  const yourMainnetBalance = useBalance(mainnetProvider, address);

  // const contractConfig = useContractConfig();

  const contractConfig = { deployedContracts: deployedContracts || {}, externalContracts: externalContracts || {} };

  // Load in your local 📝 contract and read a value from it:
  const readContracts = useContractLoader(localProvider, contractConfig);

  // If you want to make 🔐 write transactions to your contracts, use the userSigner:
  const writeContracts = useContractLoader(userSigner, contractConfig, localChainId);

  // EXTERNAL CONTRACT EXAMPLE:
  //
  // If you want to bring in the mainnet DAI contract it would look like:
  const mainnetContracts = useContractLoader(mainnetProvider, contractConfig);

  // If you want to call a function on a new block
  useOnBlock(mainnetProvider, () => {
    console.log(`⛓ A new mainnet block is here: ${mainnetProvider._lastBlockNumber}`);
  });

  // Then read your DAI balance like:
  const myMainnetDAIBalance = useContractReader(mainnetContracts, "DAI", "balanceOf", [
    "0x34aA3F359A9D614239015126635CE7732c18fDF3",
  ]);

  
  /*
  const addressFromENS = useResolveName(mainnetProvider, "austingriffith.eth");
  console.log("🏷 Resolved austingriffith.eth as:",addressFromENS)
  */

  //
  // 🧫 DEBUG 👨🏻‍🔬
  //
  useEffect(() => {
    if (
      DEBUG &&
      mainnetProvider &&
      address &&
      selectedChainId &&
      yourLocalBalance &&
      yourMainnetBalance &&
      readContracts &&
      writeContracts &&
      mainnetContracts 
    ) {
      console.log("_____________________________________ 🏗 scaffold-eth _____________________________________");
      console.log("🌎 mainnetProvider", mainnetProvider);
      console.log("🏠 localChainId", localChainId);
      console.log("👩‍💼 selected address:", address);
      console.log("🕵🏻‍♂️ selectedChainId:", selectedChainId);
      console.log("💵 yourLocalBalance", yourLocalBalance ? ethers.utils.formatEther(yourLocalBalance) : "...");
      console.log("💵 yourMainnetBalance", yourMainnetBalance ? ethers.utils.formatEther(yourMainnetBalance) : "...");
      console.log("📝 readContracts", readContracts);
      console.log("🌍 DAI contract on mainnet:", mainnetContracts);
      console.log("💵 yourMainnetDAIBalance", myMainnetDAIBalance);
      console.log("🔐 writeContracts", writeContracts);
    }
  }, [
    mainnetProvider,
    address,
    selectedChainId,
    yourLocalBalance,
    yourMainnetBalance,
    readContracts,
    writeContracts,
    mainnetContracts,
    localChainId,
    myMainnetDAIBalance,
  ]);

  const loadWeb3Modal = useCallback(async () => {
    const provider = await web3Modal.connect();
    setInjectedProvider(new ethers.providers.Web3Provider(provider));

    provider.on("chainChanged", chainId => {
      console.log(`chain changed to ${chainId}! updating providers`);
      setInjectedProvider(new ethers.providers.Web3Provider(provider));
    });

    provider.on("accountsChanged", () => {
      console.log(`account changed!`);
      setInjectedProvider(new ethers.providers.Web3Provider(provider));
    });

    // Subscribe to session disconnection
    provider.on("disconnect", (code, reason) => {
      console.log(code, reason);
      logoutOfWeb3Modal();
    });
    // eslint-disable-next-line
  }, [setInjectedProvider]);

  useEffect(() => {
    if (web3Modal.cachedProvider) {
      loadWeb3Modal();
    }
  }, [loadWeb3Modal]);

  const faucetAvailable = localProvider && localProvider.connection && targetNetwork.name.indexOf("local") !== -1;


  const container = window !== undefined ? () => window().document.body : undefined;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const [dynamicTitle, setDynamicTitle] = useState();

  const drawerWidth = 240;

  const drawer = (
    <div>
    <List>
      
      <ListItem disablePadding sx={{ display: "block" }} className="nav-btns">
        <ListItemButton
          component="a"
          href="/wallet"
          sx={{
            minHeight: 48,
            px: 2.6,
          }}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr:2.8,
            }}
          >
          <FaWallet fontSize={"20px"} />
          </ListItemIcon>
          <ListItemText>
            Create Wallet
          </ListItemText>
        </ListItemButton>
      </ListItem>

      <ListItem disablePadding sx={{ display: "block" }} className="nav-btns">
        <ListItemButton
          component="a"
          href="/buyavalanche"
          sx={{
            minHeight: 48,
            px: 2.6,
          }}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr:2.8,
            }}
          >
          <AvaxLogoSVG fill={"black"} width={"20px"} height={"20px"} />
          </ListItemIcon>
          <ListItemText>
            Buy Avalanche
          </ListItemText>
        </ListItemButton>
      </ListItem>

      <ListItem disablePadding sx={{ display: "block" }} className="nav-btns">
        <ListItemButton
          component="a"
          href="/buypapre"
          sx={{
            minHeight: 48,
            px: 2.5
          }}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr:3,
              justifyContent: "center"
            }}
          >
            <FaPaperPlane fontSize={"20px"} />
          </ListItemIcon>
          <ListItemText
            primary="Buy Papre"
          />
        </ListItemButton>
      </ListItem>
    </List>
    <Divider />
    <List>
      <ListItem disablePadding sx={{ display: "block" }} className="nav-btns">
        <ListItemButton
          component="a"
          href="https://pap.re/"
          target="_blank"
          sx={{
            minHeight: 48,
            px: 2.5
          }}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr:3,
              justifyContent: "center"
            }}
          >
            <FaQuestionCircle fontSize={"20px"} />
          </ListItemIcon>

          <ListItemText
            primary="What Is Papre?"
          />
        </ListItemButton>
      </ListItem>
      <ListItem disablePadding sx={{ display: "block" }} className="nav-btns">
        <ListItemButton
          component="a"
          href="https://pap.re/how-it-works/"
          target="_blank"
          sx={{
            minHeight: 48,
            px: 2.5
          }}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr:3,
              justifyContent: "center"
            }}
          >
            <FaCogs fontSize={"20px"} />
          </ListItemIcon>

          <ListItemText
            primary="How Does It Work?"
          />
        </ListItemButton>
      </ListItem>
      <ListItem disablePadding sx={{ display: "block" }} className="nav-btns">
        <ListItemButton
          component="a"
          href="https://pap.re/papre-road-map/"
          target="_blank"
          sx={{
            minHeight: 48,
            px: 2.5
          }}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr:3,
              justifyContent: "center"
            }}
          >
            <FaFastForward fontSize={"20px"} />
          </ListItemIcon>

          <ListItemText
            primary="The Future of Papre"
          />
        </ListItemButton>
      </ListItem>
    </List>
    </div>
  );

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed" sx={{zIndex:'1', color:'#000', backgroundColor:'#fafbfb', boxShadow:'0px 0px 15px #00000000', width: { sm: `calc(100% - ${drawerWidth}px)` }, ml: { sm: `${drawerWidth}px` }, }}>
        <Toolbar sx={{display:'flex', flexWrap:'wrap', justifyContent:'space-between',}}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 1, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
            <Box sx={{ display:{ sm: 'none' }, }}>
              <a href="/">
                <img
                  src="./papre-icon.png"
                  width="50px"
                />
              </a>
            </Box>
            <Box sx={{ mt:'10px', }}><h1>{dynamicTitle}</h1></Box>
            <Box sx={{ mt:'10px', }}>
                <Account 
                  useBurner={USE_BURNER_WALLET}
                  address={address}
                  localProvider={localProvider}
                  userSigner={userSigner}
                  mainnetProvider={mainnetProvider}
                  ///price={price}
                  web3Modal={web3Modal}
                  loadWeb3Modal={loadWeb3Modal}
                  logoutOfWeb3Modal={logoutOfWeb3Modal}
                  blockExplorer={blockExplorer}
                />
              </Box>
            
        </Toolbar>

      </AppBar>
      
      <Box component="nav" sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }} >

        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, zIndex:'10', },
            boxShadow: '#717a831c 0px 7px 30px 0px',
            zIndex:'500',
          }}
        >
          <a href="/">
            <img
              src="./papre-logo.png"
              width="175px"
              style={{margin:'12px 0 10px 20px', paddingBottom:'7px',}}
            />
          </a>
          <Divider />
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
            boxShadow: '#717a831c 0px 7px 30px 0px',
            zIndex:'500',
          }}
          open
        >
          <a href="/">
            <img
              src="./papre-logo.png"
              width="175px"
              style={{margin:'12px 0 10px 20px', paddingBottom:'7px',}}
            />
          </a>
          <Divider />
          {drawer}
        </Drawer>
      </Box>

      <Box component="main" className="mainContent" sx={{flexGrow: 1, p: 0, width: { sm: `calc(100% - ${drawerWidth}px)` } }}>

        {/* This is where the content goes */}

      <Switch>

          <Route exact path="/">
            <Home 
              yourLocalBalance={yourLocalBalance}
              readContracts={readContracts}
              setDynamicTitle={setDynamicTitle}
            />
          </Route>

          <Route path="/wallet" >
            <Wallet 
                yourLocalBalance={yourLocalBalance}
                readContracts={readContracts}
                setDynamicTitle={setDynamicTitle}
              />
          </Route>
          
          <Route path="/buypapre">
            <BuyPapre
              address={address}
              userSigner={userSigner}
              mainnetProvider={mainnetProvider}
              localProvider={localProvider}
              yourLocalBalance={yourLocalBalance}
              ///price={0} ///{price}
              tx={tx}
              writeContracts={writeContracts}
              readContracts={readContracts}
              targetNetwork={targetNetwork}
              setDynamicTitle={setDynamicTitle}
            />
          </Route>
          
          <Route path="/buyavalanche" >
            <BuyAvalanche
              address={address}
              userSigner={userSigner}
              mainnetProvider={mainnetProvider}
              localProvider={localProvider}
              yourLocalBalance={yourLocalBalance}
              ///price={price}
              tx={tx}
              writeContracts={writeContracts}
              readContracts={readContracts}
              setDynamicTitle={setDynamicTitle}
            />
          </Route>

        </Switch>

      </Box>
    </Box>
  );
}
