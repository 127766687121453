/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable prettier/prettier */
import React, {useEffect } from "react";
import { Grid, Paper, Divider, Button} from "@mui/material";
import { ReactComponent as AvaxLogoSVG } from '../components/avax-logo.svg';

export default function Wallet({ 
  setDynamicTitle,
}) {

  useEffect(() => {
    setDynamicTitle('Create Your Wallet');
  }, []);

  return (
      <div>
          <Grid container direction="row" justifyContent="space-between" alignItems="flex-start" spacing={0} gap={3} sx={{marginTop:'0px', zIndex:'0',}}>
            <Grid item xs={12} md={6}>
              <Paper className='steps shadow' elevation={1}>
                <h5>META MASK</h5>
                <h2>Choose a Wallet</h2>
                <p>To get started we'll use <a href="https://metamask.io/">MetaMask</a></p>
                
                <div className="iframe-container">
                    <iframe className="iframe-responsive" src="https://www.youtube.com/embed/WAStJtjYI_c?start=32" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                </div>

                <a href="https://metamask.io/" target="_blank">
                    <Button variant="outlined">Download MetaMask</Button>
                </a>

              </Paper>
            </Grid>

            <Grid item xs>
                <Paper className='steps shadow' elevation={1}>
                    <h5>New to Crypto?</h5>
                    <h2>Create a wallet to store your Crypto</h2>
                    <p>A crypto wallet is software that enables you to store and use cryptocurrency (Tokens, NFT's, etc.). With cryptocurrency, there is no tangible currency, no paper money to place within a physical wallet or purse.</p>
                    <a href="https://metamask.io/" target="_blank">
                        <Button variant="outlined">Download MetaMask</Button>
                    </a>
                <Divider sx={{my:4,}} />

                    <h5>Already Have A Wallet?</h5>
                    <h2>Connect your wallet to Papre</h2>
                    <p>If your wallet is already setup, you'll just need to connect Papre to your.</p>
                    <p>Let's connect meta mask:</p>
                    <ol>
                        <li>Click the "Connect Wallet" button at the top of the page.</li>
                        <li>Click the MetaMask icon</li>
                        <li>The Papre app should be connected to MetaMask.</li>
                        <li>NOTE: Make sure your MetaMask is connected to Ethereum Mainnet.</li>
                    </ol>
                
                <Divider sx={{my:4,}} />
                    
                    <h5>Is Your Wallet Connected?</h5>
                    <h2>It's time to purchase Avalanche</h2>
                    <p>If your wallet is ready to go you'll just need to purchase some Avalanche.</p>

                    <a href="/buyavalanche" target="_blank">
                        <Button variant="outlined">Buy <AvaxLogoSVG width={"17px"} height={"15px"} fill={"#000"} /> Avalanche</Button>
                    </a>
                
                </Paper>
            </Grid>

          </Grid>





      </div>
  );
}
