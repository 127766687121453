/* eslint-disable no-unused-vars */
/* eslint-disable prettier/prettier */
import React, {useEffect } from "react";
import { margin } from "@mui/system";
import { Address, Balance, PaprePrice, AvaxPriceList } from "../components";
import { ReactComponent as AvaxLogoSVG } from '../components/avax-logo.svg';
import { Grid, Paper, Divider, Button, Box } from "@mui/material";
import { FaEthereum, FaPaperPlane} from 'react-icons/fa'
import AvaxPrice from "../components/AvaxPrice";
import { AvaxLogo } from "../components/AvaxLogo";

export default function BuyAvalanche({
    address, setDynamicTitle, localProvider, price,
}) {

  useEffect(() => {
    setDynamicTitle('Buy Avalanche');
  }, []);

  return (
    <div>
      
      <Grid container direction="row" justifyContent="space-between" alignItems="flex-start" spacing={0} gap={3} sx={{marginTop:'0px', zIndex:'0',}}>
            <Grid item xs={12} md={6}>
              <Paper className='steps shadow' elevation={1}>
              <ol>
                <li>Set up Metamask: Start by installing the Metamask extension in your web browser. Visit the Metamask website (https://metamask.io) and follow the instructions to install the extension.</li>
                <li>Create or Import Account: Open Metamask by clicking on the extension icon in your browser. If you're using Metamask for the first time, you'll be guided through the process of creating a new account. If you already have a Metamask account, you can import your existing account by selecting the "Import Account" option and following the instructions to enter your account's secret phrase.</li>
                <li>Access the Portfolio Feature: Once you have set up or imported your account, navigate to the Metamask Portfolio website at https://portfolio.metamask.io/. This feature allows you to buy and manage cryptocurrencies directly from Metamask.</li>
                <li>Connect Metamask to the Portfolio: On the Portfolio website, click the "Connect Wallet" button. Metamask will prompt you to authorize the connection. Confirm the connection by clicking the "Connect" button in the Metamask popup.</li>
                <li>Choose AVAX: After connecting your wallet, you'll see a list of available cryptocurrencies. Locate AVAX (Avalanche) in the list and click on it to select it as the cryptocurrency you want to purchase.</li>
                <li>Select Payment Method: The Portfolio feature supports various payment methods. Choose the payment method that suits you best from the available options, such as credit/debit card or bank transfer. Follow the instructions provided to proceed with the payment.</li>
                <li>Specify Purchase Amount: Enter the amount of AVAX tokens you want to purchase. Some platforms might have a minimum purchase requirement, so ensure your desired amount meets any specified criteria.</li>
                <li>Complete the Purchase: Once you have entered the purchase amount, review the transaction details and confirm the purchase. The Portfolio feature will handle the transaction process, including processing your payment and transferring the AVAX tokens to your Metamask wallet.</li>
                <li>Verify AVAX Tokens in Metamask: After the transaction is completed, you can verify that you received your AVAX tokens in your Metamask wallet. Open the Metamask extension in your browser, and you should see your AVAX balance displayed alongside your other cryptocurrency balances.</li>
              </ol>
              </Paper>
            </Grid>

            <Grid item xs>
                <Paper className='steps shadow' elevation={1}>
                    <h5>Ready To Buy Avalanche?</h5>
                    <h2>Get Connected</h2>
                    <p>Make sure your wallet is connected at the top of the page</p>
                <Divider sx={{my:4,}} />
                    <h5>Purchase Avalanche</h5>
                    <h2>Avalanche price changes often</h2>
                    <p>The current price of Avalanche (AVAX) is <AvaxPriceList /></p>
                    <p>Currently You have <AvaxLogoSVG width={"17px"} height={"18px"} fill={"#000"} /> <Balance fontSize={"11px"} color={"#fff"} address={address} provider={localProvider} price={price} /> AVAX Available</p>
                    <p>Make sure you have enough Avalanche to cover the cost of a Papre Token and the fees for the exchange.</p>
                    <p>For the first round of Papre each token price is approximately <PaprePrice /> usd per token. You'll need a bit more than that amount in Avalanche to purchase a Papre Token</p>
                </Paper>
            </Grid>
        </Grid>
    </div>
  );
}